import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../components/Toast';
import getToken from '../../mixins/GetToken';
import { useNavigate } from 'react-router-dom';
import SplitToken from '../../mixins/SplitToken';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const startDateInitialState = dayjs().subtract(1, "years").startOf('day').format();
const endDateInitialState = dayjs().endOf('day').format();

console.error(startDateInitialState)
console.error(endDateInitialState)

function* createInvoice(action) {
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let invoice = yield axios.post(`/api/Invoice/PostInvoice`, action.payload, config);
        if (invoice.status === 200) {
            Toast.fire({
                title: 'Estimate Generated',
                icon: 'success',
            })
            yield put({
                type: "GET_COMPANY_INVOICES",
                payload: action.payload.DeveloperId
            });
            yield put({
                type: "GET_COMPANY_FILES",
                payload: action.payload.DeveloperId
            });
            console.log("ACTION",action.payload)
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error',
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchInvoicesforCustomer(action){
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allCustomerInvoices = yield axios.get(`/api/Invoice/GetInvoiceDataforCustomer/${action.payload.companyId}/${action.payload.customerId}/${action.payload.startDate}/${action.payload.endDate}/${action.payload.page}/${action.payload.pageSize}`, config);
        yield put({
            type: "USER_INVOICES",
            payload: allCustomerInvoices.data
        });
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });

    } catch(error){
        console.log(error);
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    }
}

function* fetchInvoicesforCompany(action){
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allCompanyInvoices = yield axios.get(`/api/Invoice/GetInvoiceDataforCompany/${action.payload.companyId}/${action.payload.customerId}/${action.payload.startDate}/${action.payload.endDate}/${action.payload.page}/${action.payload.pageSize}`,config);
        yield put({
            type: "COMPANIES_INVOICES",
            payload: allCompanyInvoices.data
        });
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });

    } catch(error){
        console.log(error);
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    }
}

function* fetchInvoicesforBotmark(action){
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allBotmarkInvoices = yield axios.get(`/api/Invoice/GetInvoiceDataforBotmark/${action.payload.companyId}/${action.payload.customerId}/${action.payload.startDate}/${action.payload.endDate}/${action.payload.page}/${action.payload.pageSize}`,config);
        yield put({
            type: "BOTMARK_INVOICES",
            payload: allBotmarkInvoices.data
        });
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    } catch(error){
        console.log(error);
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    }
}

function* updateInvoiceStatus(action){
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allCompanyInvoices = yield axios.post(`/api/Invoice/UpdateInvoiceStatus`, action.payload.invoice, config);
        yield put({
            type: "GET_CUSTOMER_INVOICES",
            payload: action.payload.customerId
        });
        console.log("HERE", allCompanyInvoices.data)
        if (allCompanyInvoices.status === 200) {
            console.error("SUCCESS")
            console.error(action.payload.invoice.isInvoiceAccepted)
            if (action.payload.invoice.isInvoiceAccepted === 2){
                console.log("ACCEPTED")
                Toast.fire({
                    title: 'Estimate Accepted',
                    icon: 'success',
                })
                action.handleInvoiceClose();
                yield put({
                    type: 'SET_IS_LOADING',
                    payload: true
                });
                // const custFiles = yield axios.get(`/api/File/GetAllFilesForCustomer/${userToken.id}/00000000-0000-0000-0000-000000000000/0/25`, config);
                yield put({
                    type: "GET_CUSTOMER_FILES",
                    payload: {companyId: action.companyId, customerId: userToken.id, page: 0, pageSize: 25 }
                });  
            }
            else if (action.payload.invoice.isInvoiceAccepted === 3){
                console.log("REJECTED")
                Toast.fire({
                    title: 'Estimate Rejected',
                    icon: 'warning',
                })
                yield put({
                    type: 'SET_IS_LOADING',
                    payload: true
                });
                yield put({
                    type: "GET_CUSTOMER_FILES",
                    payload: {companyId: action.companyId, customerId: userToken.id, page: 0, pageSize: 25 }
                });  
                action.handleInvoiceClose();
            }
        }
    } catch(error){
        Toast.fire({
            title: 'Error with Accept/Reject Estimate.',
            icon: 'error'
        })
        console.log(error)
    }
}

function* invoiceSaga() {
    yield takeLatest("CREATE_INVOICE", createInvoice);
    yield takeLatest("GET_CUSTOMER_INVOICES", fetchInvoicesforCustomer);
    yield takeLatest("GET_COMPANY_INVOICES", fetchInvoicesforCompany);
    yield takeLatest("GET_BOTMARK_INVOICES", fetchInvoicesforBotmark);
    yield takeLatest("UPDATE_INVOICE_STATUS", updateInvoiceStatus);
}

export default invoiceSaga;