import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import {
    Box,
    Button,
    Modal,
    Hidden,
    Typography,
} from "@mui/material";
import MessageDisplay from './MessageDisplay';
import CustomerStatusChange from './CustomerStatusChange';
import Reply2Customer from './Reply2Customer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function CustomerDataGrid({messages, i}){
    /// datagrid that displays all customers who have messaged processor
    const dispatch = useDispatch();
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    
    function showMessage(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: cellValues.row.id,
            companyId: cellValues.row.companyId,
            created: cellValues.row.created,
            deleted: cellValues.row.deleted,
            message: cellValues.row.message,
            requestStatus: cellValues.row.requestStatus,
            userId: cellValues.row.userId,
            userEmail: cellValues.row.userEmail,
            userName: cellValues.row.customerName,
            reply: cellValues.row.reply
        })
        handleOpen();
    };
    function editStatus(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: cellValues.row.id,
            companyId: cellValues.row.companyId,
            created: cellValues.row.created,
            deleted: cellValues.row.deleted,
            message: cellValues.row.message,
            requestStatus: cellValues.row.requestStatus,
            userId: cellValues.row.userId,
            userEmail: cellValues.row.userEmail,
            userName: cellValues.row.customerName,
            reply: cellValues.row.reply
        })
        handleOpen1();
    };

    function replyMessage(cellValues){
        setRowItem({
            id: cellValues.row.id,
            companyId: cellValues.row.companyId,
            created: cellValues.row.created,
            deleted: cellValues.row.deleted,
            message: cellValues.row.message,
            requestStatus: cellValues.row.requestStatus,
            userId: cellValues.row.userId,
            userEmail: cellValues.row.userEmail,
            userName: cellValues.row.customerName,
            reply: cellValues.row.reply
        })
        handleOpen2();
    };

    function deleteMessage(cellValues){
        setRowItem({
            id: cellValues.row.id,
        })
        dispatch({ type: 'DELETE_MESSAGES', payload: {id:cellValues.row.id, companyId: cellValues.row.companyId} })
    };

    const columns = [
        { field: 'companyContact', headerName: 'Company', flex: 1, headerClassName: 'header', editable: false,},
        { field: 'customerName', headerName: 'User', flex: 1, headerClassName: 'header', editable: false,},
        { field: 'companyEmail', headerName: 'Email', flex: 1, headerClassName: 'header', editable: false,},
        { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header', editable: false,
            renderCell: (cellValues) => {
                if (cellValues.row.requestStatus === 0){
                    return(
                        <div>
                            <Button variant="contained" color="secondary" onClick={() => editStatus(cellValues)}>Pending</Button>
                        </div>
                    )
                } else if(cellValues.row.requestStatus === 1){
                    return(
                        <div>
                            <Button variant="contained" color="success" onClick={() => editStatus(cellValues)}>Approved with Credit</Button>
                        </div>
                    )
                } else if(cellValues.row.requestStatus === 2){
                    return(
                        <div>
                            <Button variant="contained" color="warning" onClick={() => editStatus(cellValues)}>Approved with out Credit</Button>
                        </div>
                    )
                } else if(cellValues.row.requestStatus === 3){
                    return(
                        <div>
                            <Button variant="contained" color="error" onClick={() => editStatus(cellValues)}>Denied</Button>
                        </div>
                    )
                }
            }
        },
        { field: 'messageTab', headerName: 'Message', flex: .5, headerClassName: 'header', sortable: false,
            renderCell: (cellValues)=> (
                <div>
                    <Button variant="contained" color="primary" onClick={() => showMessage(cellValues)}>Message</Button>
                </div>
            )
        },
        { field: 'replyTab', headerName: 'Reply', flex: .5, headerClassName: 'header', sortable: false,
            renderCell: (cellValues)=> (
                <div>
                    <Button variant="contained" color="secondary" onClick={() => replyMessage(cellValues)}>Reply</Button>
                </div>
            )
        },
        { field: 'Remove', flex: .5, headerClassName: 'header', sortable: false,
            renderCell: (cellValues)=> {
                if(cellValues.row.requestStatus === 3){
                    return(
                <div>
                    <Button variant="contained" color="error" onClick={() => deleteMessage(cellValues)}>Delete</Button>
                </div>
                    )
                }
            }
        },
    ];
    
    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='40%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return(
        <Box sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '80vh' }}>
            <DataGrid
                key={i}
                editMode="row"
                editRowsModel={editRowsModel}
                columns={columns}
                rows={messages}
                components={{
                    Toolbar: CustomToolBar
                }}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MessageDisplay item={rowItem} style={style}/>
            </Modal>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CustomerStatusChange item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Reply2Customer item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
            </Modal>
        </Box>
    )
};

export default CustomerDataGrid;