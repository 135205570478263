import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Container,
    TextField,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    Box,
    FormControl,
    InputLabel,
    Button,
    Tab,
    Tabs,
    inputAdornmentClasses,
    Menu,
    MenuItem
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';
import { useEffect } from "react";
import moment from 'moment';
import PointListTable from "./PointListTable";
// import ReactPDF from '@react-pdf/renderer'
// import {PDFViewer} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

function BotmarkEstimateView({ botmarkEstimateObj, handleCloseBotmarkEstimate }) {
    /// auto generation of botmark invoice for processors
    const file = useSelector((state) => state.FileReducer.file.info);
    const fileReport = useSelector((state) => state.FileReducer.file);
    const userData = useSelector((state) => state.UserReducer.user);
    const BotmarkPrimaryPrices = useSelector((state) => state.CompanyReducer.BotmarkPrices);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    console.log(company)
    const dispatch = useDispatch();

    const [invoiceOption, setInvoiceOption] = React.useState([]);
    const [botmarkTotal, setBotmarkTotal] = React.useState(0);
    const [pointTypeTotal, setPointTypeTotal] = React.useState(0);
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);
    const [customer, setCustomer] = React.useState('');
    const [selectedCustomer, setSelectedCustomer] = React.useState();

    let data = [];
    let invoiceTotal = 0;
    let taxTotal = 0;
    let finalTotal = 0;
    data = JSON?.parse(botmarkEstimateObj?.botmarkInvoiceValues);
    let pointList = JSON.parse(botmarkEstimateObj.pointList);

    let invoiceData = {
            date: moment().format("MM/DD/YYYY"),
            estimateNumber: data.estimateNumber,
            items: [],
            taxes: [],
            pointTypeTotal: pointTypeTotal,
            customerInfo: selectedCustomer,
            processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
            botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
        };

    console.log("OBJECT",botmarkEstimateObj);

    //
    /// setting price options ///
    //
    for (let i = 0; i < invoiceOption.length; i++) {
        invoiceData.items.push({
            companyId: invoiceOption[i].companyId,
            itemName: invoiceOption[i].itemName,
            description: invoiceOption[i].description,
            unit: invoiceOption[i].unit,
            isForTaxes: invoiceOption[i].isForTaxes,
            isBotmarkPrimary: invoiceOption[i].isBotmarkPrimary,
            id: invoiceOption[i].id,
            rate: invoiceOption[i].rate,
            created: invoiceOption[i].created,
            updated: invoiceOption[i].updated,
            deleted: invoiceOption[i].deleted,
            quantity: invoiceOption[i]?.quantity,
            total: invoiceOption[i]?.quantity*invoiceOption[i]?.rate
        });
    }

    //
    /// dispatch data ///
    //
    let sendInvoiceObject = {
        Id: botmarkEstimateObj.botmarkEstimateId,
        CustomerId: botmarkEstimateObj.botmarkInvoiceDeveloperId,
        DeveloperId: botmarkEstimateObj.botmarkInvoiceDeveloperId,
        InvoiceValues: JSON.stringify(invoiceData),
        OpenInvoice: true,
        JobId: botmarkEstimateObj.id,
        isSent: true,
        isInvoiceAccepted: parseInt(2)
    };
    let sendForCustomerInvoiceObject = {
        Id: botmarkEstimateObj.botmarkEstimateId,
        CustomerId: botmarkEstimateObj.botmarkInvoiceCustomerId,
        DeveloperId: botmarkEstimateObj.botmarkInvoiceDeveloperId,
        InvoiceValues: JSON.stringify(invoiceData),
        OpenInvoice: true,
        JobId: botmarkEstimateObj.id,
        isSent: true,
        isInvoiceAccepted: parseInt(4)
    };

    //
    //// Send Estimate with Personal Use Paramaters ////
    //
    function acceptEstimate() {
        dispatch({ type: "CREATE_INVOICE", payload: sendInvoiceObject });
        handleCloseBotmarkEstimate();

            // dispatch({ type: "UPDATE_INVOICE_STATUS", payload: acceptInvoice, handleInvoiceClose });
            
            // ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
            // ReactPDF.renderToStream(<EstimateView />);
            // ReactDOM.render(<BotmarkEstimateView />, document.getElementById('root'));
            // const { toPDF, targetRef } = usePDF({ filename: `${item?.companyContact?.replace(' ', '_')}-${item?.name?.split('.')[0]}.pdf` });
    
            // const sgMail = require('@sendgrid/mail')
            // sgMail.setApiKey(process.env.SENDGRID_API_KEY);
    
            // const fs = require("fs");
    
            // pathToAttachment = `${__dirname}/attachment.pdf`;
            // attachment = fs.readFileSync(pathToAttachment).toString("base64");
    
            // const msg = {
            // to: 'test@example.com', // Change to your recipient
            // from: 'test@example.com', // Change to your verified sender
            // subject: 'Sending with SendGrid is Fun',
            // text: 'and easy to do anywhere, even with Node.js',
            // html: '<strong>and easy to do anywhere, even with Node.js</strong>',
            // attachments: [
            //     {
            //       content: attachment,
            //       filename: "attachment.pdf",
            //       type: "application/pdf",
            //       disposition: "attachment"
            //     }
            //   ]
            // };
    
            // sgMail
            // .send(msg)
            // .then((response) => {
            //     console.log(response[0].statusCode)
            //     console.log(response[0].headers)
            // })
            // .catch((error) => {
            //     console.error(error)
            // })
    }

    //
    //// Send Estimate with Customer Use Paramaters ////
    //
    function acceptForCustomerEstimate() {
        dispatch({ type: "CREATE_INVOICE", payload: sendForCustomerInvoiceObject });
        handleCloseBotmarkEstimate();
    }

    //
    //// Logic to decide which payload to send for estiamte acceptance
    //
    function estimateAcceptanceLogic(){
        console.log("HIT", botmarkEstimateObj.botmarkInvoiceCustomerId, botmarkEstimateObj.botmarkInvoiceDeveloperId);
        if(customer == "00000000-0000-0000-0000-000000000000"){
            console.log("PROCESSOR ACCEPT");
            swal({
                title: "ACCEPT ESTIMATE",
                text: `Estimate is being accepted for ${company.name}'s Internal Use, once accepted this cannot be changed. Is this Correct?`,
                icon: "warning",
                buttons: true,
                dangerMode: false,
            })
            .then((willUpdate) => {
                if (willUpdate) {
                    acceptEstimate();
                } else {
                    swal({
                        title: `Estimate not Accepted.`
                    });
            }})
        } else {
            console.log("CUSTOMER ACCEPT");
            swal({
                title: "ACCEPT ESTIMATE",
                text: `Estimate is being accepted for ${selectedCustomer.userCompany}: ${selectedCustomer.customerName}, once accepted this cannot be changed. Is this Correct?`,
                icon: "warning",
                buttons: true,
                dangerMode: false,
            })
            .then((willUpdate) => {
                if (willUpdate) {
                    acceptForCustomerEstimate();
                } else {
                    swal({
                        title: `Estimate Not Accepted.`
                    });
            }})
        }
    };

    //
    /// For File name selection in invoice ///
    //
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    }

    //
    /// Matching points to preset botmark price option ///
    //
    let pointTotal = 0;
    let tempTotal = 0;
    function matchItems(item, b) {
        for (let i = 0; i < pointList?.length; i++) {
            if (item.itemName === pointList[i].Description) {
                item.quantity = pointList[i].Quantity
                if (item.quantity != 0 && pointList[i].Enabled == true){
                    invoiceOption.push(item);
                    setBotmarkTotal(tempTotal += item.quantity*item.rate);
                }
            }
        }
    }
    
    function pointCounter(){
        for (let i = 0; i < pointList?.length; i++) {
            if (pointList[i].TypeEnum == 1 || pointList[i].TypeEnum == 2 || pointList[i].TypeEnum == 3 ){
                pointTotal += pointList[i].Quantity;
            }
        }
        setPointTypeTotal(pointTotal);
    }

    /// This sets the dropdown to select a customer for a plan in invoicing ///
    const allCustomers = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    const customers = [];
    allCustomers?.forEach(customerPusher);
    function customerPusher(allCustomers) {
        if (allCustomers.requestStatus === 1 || allCustomers.requestStatus === 2){
        customers.push({
            id: allCustomers.id,
            companyId: allCustomers.companyId,
            created: allCustomers.created,
            deleted: allCustomers.deleted,
            message: allCustomers.message,
            requestStatus: allCustomers.requestStatus,
            userId: allCustomers.userId,
            userEmail: allCustomers.userEmail,
            customerName: allCustomers.customerName,
            userCompany: allCustomers.companyContact,
            companyStatus: allCustomers.companyStatus,
            userCompanyId: allCustomers.userCompanyId,
        })}
    };
    console.log(allCustomers);
    console.log(customers);

    /// Handle assignment of a customer to a plan ///
    const handleChange = (event) => {
        setCustomer(event.target.value);
    };
    
    function setCustomertoFile(value){
        if (customer != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < customers.length; i++){
                if (customer == customers[i].userCompanyId){
                    setSelectedCustomer(customers[i]);
                }
            }
        } else {
            setSelectedCustomer();
        }
        dispatch({ type: 'SET_CUSTOMER_FOR_FILE', payload: {file: file.id, customer, userCompany: company.id}  });
    };
    
    function initCustomer(){
        setCustomer(botmarkEstimateObj.customerId);
        if (customer != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < customers.length; i++){
                if (botmarkEstimateObj.customerId == customers[i].userCompanyId){
                    setSelectedCustomer(customers[i]);
                }
            }
        }
    };

    const [results, setResults] = React.useState(fileReport.file);
    const [value, setValue] = React.useState(0);
    const [summary, setSummary] = React.useState(fileReport.summary);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function initPrices(){
        BotmarkPrimaryPrices.forEach(matchItems);
    };

    React.useEffect(() => {
        // dispatch({ type: 'FIND_BOTMARK_PRICES' });  
        setResults(fileReport.file);
        setSummary(fileReport.summary);
    }, [fileReport.file, fileReport.summary]);
    
    React.useEffect(()=>{
        initCustomer();
        initPrices();
        pointCounter();
    },[company] ,[file] , [customers],)

    return (
        // <PDFViewer>
        <Container style={{ backgroundColor: 'lightgray', maxWidth: '100vw', position: 'fixed', height: '100%' }}>
            <Container className='estimatePage' maxWidth="sm" style={{ padding: 20, width: '75vw', display: 'flex', flexDirection: 'column', overflowY: 'scroll', height:'inherit' }}>
        <Box sx={{ width: '95%' }}>
                <Tabs value={value} onChange={handleTabChange} centered style={{borderBottom:'2px solid black', paddingBottom:'10px'}}>
                    <Tab label="Estimate Review"/>
                    <Tab label="Validation & Summary"/>
                </Tabs>
                <TabPanel value={value} index={0} style={{}}>
                    <Container>
                        <Typography variant="h4" align="right">
                            Estimate Review
                        </Typography>
                        <p style={{ display: "flex", justifyContent: 'right', width: "100%" }}>
                            <span style={{ marginRight: 50 }}>Date: {moment().format("MM/DD/YYYY")}</span>
                            <span># {data?.estimateNumber}</span>
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ flex: 1, margin: 2 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                        <h4 style={{ display: "flex", justifyContent: 'left' }}>From Botmark:</h4>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Botmark Inc.</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>7945 168 Ave SE</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Mooreton, North Dakota</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>58061, United States</span></span>
                                    </div>
                                </div>
                                <div style={{ flex: 1, margin: 2 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>

                                        <h4 style={{ display: "flex", justifyContent: 'left' }}>To Processor:</h4>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {company.name}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {userData?.firstName} {userData?.lastName}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userData?.email}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Phone: {company?.phoneNumber}</span></span>

                                        {/* <span></span><br />
                                        {company.companyStatus === 0 &&
                                            <span style={{color:'purple', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                        }
                                        {company.companyStatus === 1 &&
                                            <span style={{color:'green', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                        }
                                        {company.companyStatus === 2 &&
                                            <span style={{color:'darkorange', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                        }
                                        {company.companyStatus === 3 &&
                                            <span style={{color:'red', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                        } */}
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 1, margin: 2 }}>
                                { company.processExternal == true &&
                                    <Box>
                                        <form style={{display:'flex', flexDirection:'column'}}>
                                            <h4>Customer Select</h4>
                                            <p>Select customer to set any applicable pricing options and to generate customer estimate upon acceptance of this estimate.</p>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={customer}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem>
                                                {customers.map((item) =>(
                                                    <MenuItem value={item?.userCompanyId}>
                                                        {item?.requestStatus == 0 &&
                                                            <span style={{color:'purple'}}>{item?.userCompany} : {item?.customerName}</span>
                                                        }
                                                        {item?.requestStatus == 1 &&
                                                            <span style={{color:'green'}}>{item?.userCompany} : {item?.customerName}</span>
                                                        }
                                                        {item?.requestStatus == 2 &&
                                                            <span style={{color:'darkOrange'}}>{item?.userCompany} : {item?.customerName}</span>
                                                        }
                                                        {item?.requestStatus == 3 &&
                                                            <span style={{color:'red'}}>{item?.userCompany} : {item?.customerName}</span>
                                                        }
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Button onClick={()=>setCustomertoFile()}>Update Customer</Button>
                                        </form>
                                        { selectedCustomer != undefined &&
                                            <div>
                                                <h4 style={{ display: "flex", justifyContent: 'left' }}>For Customer:</h4>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {selectedCustomer?.userCompany}</span></span>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {selectedCustomer?.customerName}</span></span>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Email: {selectedCustomer?.userEmail}</span></span>
                                                {/* <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Customer Credit Status</span></span> */}
                                                {/* {selectedCustomer?.requestStatus === 0 &&
                                                    <span style={{color:'purple', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                                }
                                                {selectedCustomer?.requestStatus === 1 &&
                                                    <span style={{color:'green', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                                }
                                                {selectedCustomer?.requestStatus === 2 &&
                                                    <span style={{color:'darkOrange', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                                }
                                                {selectedCustomer?.requestStatus === 3 &&
                                                    <span style={{color:'red', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                                }                                            */}
                                            </div>
                                        }
                                    </Box>
                                }
                            </div>
                            <PointListTable pointList={pointList} name={botmarkEstimateObj.name} id={botmarkEstimateObj.id} pointTypeTotal={pointTypeTotal}/>
                        </div>
                        <br />
                        <Typography variant="body1">
                            <b>Estimate</b>
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                                <TableCell className="estimateTableCellVariant">Item</TableCell>
                                <TableCell className="estimateTableCellVariant">Description</TableCell>
                                <TableCell className="estimateTableCellVariant">Qty</TableCell>
                                <TableCell className="estimateTableCellVariant">U/M</TableCell>
                                <TableCell className="estimateTableCellVariant">Rate</TableCell>
                                <TableCell className="estimateTableCellVariant">Amount</TableCell>
                                <TableCell className="estimateTableCellVariant"></TableCell>
                            </TableHead>
                            <TableBody>
                                {invoiceOption?.map((item, i) => (
                                    <TableRow>
                                        <TableCell className="estimateTableCellVariant">{item?.itemName}</TableCell>
                                        <TableCell className="estimateTableCellVariant">{item?.description}</TableCell>
                                        <TableCell className="estimateTableCellVariant">{item?.quantity}</TableCell>
                                        <TableCell className="estimateTableCellVariant">{item?.unit}</TableCell>
                                        <TableCell className="estimateTableCellVariant">{item?.rate}</TableCell>
                                        <TableCell className="estimateTableCellVariant"> 
                                            <CurrencyFormat 
                                                value={Number(item?.quantity) * item?.rate}
                                                displayType={'text'} thousandSeparator={true}
                                                prefix={'$'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            >
                                            </CurrencyFormat>
                                        </TableCell>
                                        <TableCell className="estimateTableCellVariant"></TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell className="estimateTableCellVariant"></TableCell>
                                    <TableCell className="estimateTableCellVariant"></TableCell>
                                    <TableCell className="estimateTableCellVariant"></TableCell>
                                    <TableCell className="estimateTableCellVariant"></TableCell>
                                    <TableCell className="estimateTableCellVariant"><b>Total:</b></TableCell>
                                    <TableCell className="estimateTableCellVariant">
                                        <CurrencyFormat
                                            value={botmarkTotal}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </TableCell>
                                    <TableCell className="estimateTableCellVariant"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <br />
                    </Container>
                    <span>Accepting Estimate: Agree to payment amount and generate Estimate. Plans will become avalible after Estimate has been Accepted.</span>
                    <br />
                    <Button onClick={() => handleCloseBotmarkEstimate()}>Back</Button>
                    <Button onClick={() => estimateAcceptanceLogic()}>Accept</Button>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box m={2} sx={{  display: 'flex', flexDirection:'row', justifyContent: 'center',height: '100%', minHeight: '300px'}}>
                        <div style={{flex:2, margin: 20}}>
                            <h4 style={{textAlign:'left'}}>Validation:</h4>
                            <pre style={{textAlign:'left'}}>
                                {results}
                            </pre>
                        </div>
                        <div style={{flex:1, margin:20}}>
                            <h4 style={{textAlign:'left'}}>Summary:</h4>
                                <pre style={{textAlign:'left'}}>
                                    {summary}
                                </pre>
                        </div>
                    </Box> 
                </TabPanel>
            </Box>
            </Container>
        </Container>
        // {/* </PDFViewer> */}
    )
}

export default BotmarkEstimateView;