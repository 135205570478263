import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Page from '../../components/Page';
import {
    Grid,
    Typography,
    Box,
    Card,
    CardHeader
} from '@mui/material';
import Youtube from 'react-youtube';

const videos = [
    {
        title: "Robotic Layout",
        videoId: 'RvRxfZoowvI'
    },
    {
        title: "Robotic layout using Botmark system",
        videoId: 'P-YrpP0XY4M'
    },
    {
        title: 'Robotic layout using Botmark system (2)',
        videoId: 'Pqzcknda5IU'
    }
]

const Videos = () => {
    ///// Currently vids either require a youtube Id or a embed version of a youtube link to function /////
    const dispatch = useDispatch();
    // const vid1 = useSelector((state) => state.FileReducer.vid1);
    // const vid2 = useSelector((state) => state.FileReducer.vid2);
    const user = useSelector((state) => state.UserReducer.isAuthenticated);
    const vids = useSelector((state) => state.FileReducer.vids);
    console.log(vids);

    function VideoCard({ item }) {
        const requiredRole = vidTypeMap.get(item.vidType);
        return user.theUserRoles[0] === requiredRole ?
            <Card style={{ border: '1px solid black', margin: 'auto' }}>
                <CardHeader sx={{height:'40px'}} title={item.title}/>
                {/* <iframe src={item.vidUrl}></iframe> */}
                <Youtube videoId={item.vidUrl} opts={opts} />
            </Card>
            : <></>
    }

    const vidTypeMap = new Map();
    vidTypeMap.set(1, "AD");
    vidTypeMap.set(2, "PL");
    vidTypeMap.set(3, "DO");
    vidTypeMap.set(4, "DAD");
    vidTypeMap.set(5, "BI");
    vidTypeMap.set(6, "RBI");

    const opts = {
        height: '300px',
        width: '100%',
    }

    React.useEffect(() => {
        dispatch({ type: 'GET_VIDEO_URLS'});
    },[]);

    return (
        <Page title="Tutorial">
            <Box p={3}>
                <Typography variant='h3' textAlign="left" marginBottom="20px">
                    Tutorial Videos
                </Typography>
                <Grid container spacing={3}>
                    {videos.map((x, i) => (
                        <Grid key={i} item xs={12} sm={12} md={6} lg={4} >
                            <Card style={{ border: '1px solid black' }}>
                                <CardHeader sx={{height:'40px'}} title={x.title}/>
                                <Youtube videoId={x.videoId} opts={opts} />
                            </Card>
                        </Grid>
                    ))}
                    {vids.map((x,i) => ( 
                        <VideoCard item={vids[i]}/>
                    ))}
                </Grid>
            </Box>
        </Page>
    )
};

export default Videos;