import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from "@mui/x-data-grid";
import {
    Box,
    Hidden,
    Button,
    Typography,
    Chip,    
    Modal,
} from "@mui/material";
import {
    CreditCard,
    CheckCircle,
    Loader,
    FileText,
    Edit2,
    AlertCircle,
    Edit, 
    RefreshCw,
    Wifi,
    WifiOff,
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import { Form, Label, Input } from 'reactstrap';
import getToken from '../../mixins/GetToken';
import SplitToken from '../../mixins/SplitToken';
import { useRouteLoaderData } from "react-router";
import { useEffect, useState } from "react";

function LicenseTab (){
    const dispatch = useDispatch();
    const licensesData = useSelector((state) => state.LicenseReducer.allLicenses);
    const currentCompany = useSelector((state) => state.CompanyReducer?.userCompanies[0]);
    console.log("LICENSE", licensesData)

    const licenseReadableData = [];
    licensesData?.forEach(licensePusher);
    function licensePusher(licensesData) {
        licenseReadableData.push({
            id: licensesData.id,
            licenseNumber: licensesData.licenseNumber,
            used: licensesData.used,
            userId: licensesData.userId,
            licenseUserName: `${licensesData.userFN} ${licensesData.userLN}`,
            licenseDayUsed: licensesData.licenseDayUsed, 
            expirationLength: licensesData.expirationLength, 
            licenseCreated: licensesData.licenseCreated,
            licenseUpdated: licensesData.licenseUpdated,
            licenseExpiration: licensesData.licenseExpiration,
            licenseActivation: licensesData.licenseActivation,
            notes: licensesData.notes,
        });
    };
    console.log("License data", licenseReadableData);

    const columns = [
        { field: 'licenseNumber', headerName: 'License #', flex: .75, headerClassName: 'header' },
        { field: 'used', headerName: 'Status', flex: .3, headerClassName: 'header',
            renderCell: (cellValues)=>{
                if (cellValues.row.used === true && cellValues.row.licenseDayUsed <= 0 && cellValues.row.licenseDayUsed != null){
                    return(
                        <Chip icon={<WifiOff/>} variant="contained" color="warning" label="Expired"/>
                    )
                }
                else if (cellValues.row.used === true && cellValues.row.licenseDayUsed > 0){
                    return(
                        <Chip icon={<Wifi/>} variant="contained" color="success" label="Activated"/>
                    )
                } 
                else if (cellValues.row.used === true && cellValues.row.licenseDayUsed == null && cellValues.row.expirationLength == null){
                    return(
                        <Chip icon={<Wifi/>} variant="contained" color="success" label="Activated"/>
                    )
                } 
                else if(cellValues.row.used === false){
                    return(
                        <Chip icon={<WifiOff/>} variant="contained" color="secondary" label="Not Activated"/>
                    )
                }
            } 
        },
        { field: 'licenseUserName', headerName: 'User', flex: .5, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.licenseUserName == `null null`){
                    return(
                        <p>N/A</p>
                    )
                } else {
                    return(
                        <p>{cellValues.row.licenseUserName}</p>
                    )
                }
            }
        },
        { field: 'licenseCreated', headerName: 'Generated Date', flex: .3, headerClassName: 'header',
            renderCell: (params) => {
            let date = new Date(params.row.licenseCreated);
            const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              };
            return date.toLocaleString("en-US",options)
        }},
        { field: 'licenseActivation', headerName: 'Activated Date', flex: .3, headerClassName: 'header', renderCell: (params) => {
            if (params.row.used != true){
                return (<string>N/A</string>)
            } else {
                let date = new Date(params.row.licenseActivation);
                const options = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                };
                return date.toLocaleString("en-US",options)            }
        }},
        { field: 'licenseExpiration', headerName: 'Expiration Date', flex: .3, headerClassName: 'header',
            renderCell: (params) => {
                if(params.row.licenseDayUsed == null && params.row.expirationLength == null){
                    return(<string>Perpetual</string>)
                }else if(params.row.used == false){
                    return(<string>N/A</string>)
                }else{
                    let date = new Date(params.row.licenseExpiration);
                    const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      };
                    return date.toLocaleString("en-US",options)
                }
        }},
        { field: 'licenseDayUsed', headerName: 'Duration', flex: .3, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.licenseDayUsed > 0 && cellValues.row.used === true){
                    return(
                        <p>{cellValues.row.licenseDayUsed} / {cellValues.row.expirationLength}</p>
                    )
                } else if (cellValues.row.used === false && cellValues.row.expirationLength != null){
                    return(
                        <p>Days: {cellValues.row.expirationLength}</p>
                    )
                } else if (cellValues.row.licenseDayUsed <= 0 && cellValues.row.licenseDayUsed != null){
                    return(
                        <p>Expired</p>
                    )
                } else if (cellValues.row.licenseDayUsed == null && cellValues.row.expirationLength == null) {
                    return(
                        <p>Perpetual</p>
                    )
                }
            }
        },
        // { field: 'notes', headerName: 'Notes', flex: .3, headerClassName: 'header',
        //     renderCell: (cellValues) => {
        //         return(<Button onClick={() => showInfo(cellValues)}>Notes</Button>)
        //     }
        // }
    ];

    const rows = licenseReadableData;

    useEffect(() => {
        // dispatch({ type: 'GET_LICENSES', payload: 1 });
        dispatch({ type: 'GET_LICENSES', payload: {companyId: currentCompany.id, type: '1'} });

    }, []);

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='30%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return(
        <div>
            <h4>License History</h4>
            <DataGrid
                components={{
                    Toolbar: CustomToolBar
                }}
                editMode="row"
                sx={{ height: '70vh' }}
                // onRowEditCommit={handleSave}
                // editRowsModel={editRowsModel}
                // onEditRowsModelChange={handleEditRowsModelChange}
                columns={columns}
                rows={rows}
            />
        </div>
    )
};

export default LicenseTab;