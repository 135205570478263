import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    colors,
    Badge,
    Tabs,
    Tab,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Grow,
    Button,
    ClickAwayListener,
    Divider,
    List,
} from '@mui/material';
import {
    Lock as LockIcon,
    UserPlus as UserPlusIcon,
    Home as HomeIcon,
    DollarSign as DollarIcon,
    Info as InfoIcon,
    User as UserIcon,
    Users as UsersIcon,
    LogOut as LogoutIcon,
    Upload as UploadIcon,
    Folder as FolderIcon,
    Film as FilmIcon,
    Search as SearchIcon,
    MessageCircle as MessageIcon,
    Inbox as InboxIcon,
    Clipboard as ClipboardIcon,
    Mail as MailIcon,
    FileText as FileTextIcon,
    FilePlus as FilePlusIcon,
    Layers as LayersIcon,
    FileMinus as FileMinusIcon,
    Printer as PrinterIcon,
    BookOpen as BookOpenIcon,
    Book as BookIcon,
    List as ListIcon,
    Paperclip as PaperclipIcon,
    Thermometer as ThermometerIcon
  } from 'react-feather';
import { Notifications } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../src/components/Logo';
import { useSelector, useDispatch } from 'react-redux';
import "./Layout.css";
import NavBar from './NavBar/index';
import NavItem from './NavBar/NavItem';
import SplitToken from '../mixins/SplitToken';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 21,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}));

const uploadPlan = {
    href: '/upload',
    icon: UploadIcon,
    title: 'Upload Plan'
};

const plans = {
    href: '/',
    icon: FileTextIcon,
    title: 'Plans'
};

const account = {
    href: '/account',
    icon: InfoIcon,
    title: 'Account'
};

const contact = {
    href: '/contact',
    icon: MessageIcon,
    title: 'Contact'
};

const logout = {
    href: '/logout',
    icon: LogoutIcon,
    title: 'Log Out'
};

const login = {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
};

const register = {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
};

const tutorial = {
    href: '/instructions',
    icon: FilmIcon,
    title: 'Tutorial'
};

const findProcessor = {
    href: '/findProcessor',
    icon: SearchIcon,
    title: 'Find Processor'
}

const planInbox = {
    href: '/planInbox',
    icon: LayersIcon,
    title: 'Plan Inbox'
};

const downloaderPlanInbox = {
    href: '/',
    icon: LayersIcon,
    title: 'Plan Inbox'
};

const receivedEstimates = {
    href: '/InvoicesToMe',
    icon: BookOpenIcon,
    title: 'Received Invoices'
};

const sentEstimates = {
    href: '/InvoicesFromMe',
    icon: BookIcon,
    title: 'Sent Invoices'
};

const customerManagement = {
    href: '/customers',
    icon: InboxIcon,
    title: 'Customer Management'
};

const processorContacts = {
    href: '/Contacts',
    icon: UsersIcon,
    title: 'Processor Contacts'
};

///standard menu render options
const items = [ tutorial ];

///menu render options for processor Admins
const admin = [ planInbox, uploadPlan, plans ];

///menu render options for external processor Admins
const externalAdmin = [ planInbox, uploadPlan, plans, customerManagement ];

/// menu options for downloader Admins
const downloaderAdmin = [ downloaderPlanInbox ];
// const downloaderAdmin = [ downloaderPlanInbox, receivedEstimates, processorContacts, findProcessor ];

/// menu options for downloader Admins
const downloaderOnly = [ downloaderPlanInbox ];

/// menu options for downloader Admins
const allBilling = [ sentEstimates, receivedEstimates ];

/// menu options for downloader Admins
const recievedBilling = [ receivedEstimates ];

/// menu options for a planner user
const planner = [ plans, uploadPlan ];

/// menu options for a planner user
const plannerDownload = [ plans ];

/// login/out menu options
const loginItems = [ login, register ];

///menu options for Super Admins (develpers/BotwebOwner)
const superAdmin = [
    {
        href: '/companiesViewer',
        icon: ListIcon,
        title: 'Companies'
    },
    {
        href: '/BotmarkInvoiceTable',
        icon: BookIcon,
        title: 'Botmark Invoices'
    },
    {
        href: '/RulerCreator',
        icon: ThermometerIcon,
        title: 'Rulers'
    },
    {
        href: '/licensing',
        icon: ClipboardIcon,
        title: 'Licensing'
    },
    {
        href: '/PDFupload',
        icon: PaperclipIcon,
        title: 'PDF/Tutorial Upload'
    },
];

const roleOptionMap = new Map();
roleOptionMap.set("AD", [...admin]);
roleOptionMap.set("EXAD", [...externalAdmin]);
roleOptionMap.set("DAD", [...downloaderAdmin]);
roleOptionMap.set("BI", [...allBilling]);
roleOptionMap.set("PL", [...planner]);
roleOptionMap.set("RBI", [...recievedBilling]);
roleOptionMap.set("DO", [...downloaderOnly]);
roleOptionMap.set("PD", [...plannerDownload]);


const MobileMenu = ({ onMobileNavOpen, value, handleTabChange, handleMobleTabChange, setMobileNavOpen, isMobileNavOpen }) => {
    const [notifications] = useState([{}, {}]);
    const auth = useSelector((state) => state.UserReducer.isAuthenticated);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.UserReducer.isAuthenticated);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const [customListItems, setCustomListItems] = React.useState([]);
    const listItems = [...items];
    const userInfo = SplitToken();
    const allCompany = useSelector((state) => state.CompanyReducer.allCompanies);

    const pendingCompany = [];
    allCompany?.forEach(companyPusher);
    function companyPusher(allCompany) {
        if (allCompany.companyStatus === 0){
            pendingCompany.push({
                companyStatus: allCompany.companyStatus,
            })
        }
    };

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    function renderMenu() {
        const isCompanyApproved = !!userCompany[0] 
        && (userCompany[0].companyStatus == 1 || userCompany[0].companyStatus == 2)

        if (!isCompanyApproved) {
            //TODO set the options accordingly
            return;
        }

        const role = user.theUserRoles[0];
        let menuOptions;
        const isExternalProcessor = !!userCompany[0].processExternal;

        if (role === "AD" && isExternalProcessor) {
            menuOptions = roleOptionMap.get("EXAD");
        }
        else {
            menuOptions = roleOptionMap.get(role) ?? [];
        }        

        setCustomListItems(menuOptions);
        // if (openMobile && onMobileClose) {
        //     onMobileClose();
        // };
        // // setCustomListItems(menuOptions);
        // if (openMobile && onMobileClose) {
        //     onMobileClose();
        // };
    };
  
    // return focus to the button when we transitioned from !open -> open
    // const prevOpen = React.useRef(open);
    // React.useEffect(() => {
    //   if (prevOpen.current === true && open === false) {
    //     anchorRef.current.focus();
    //   }
  
    //   prevOpen.current = open;
    // }, [open]);

    
    // function notif() {
    //     if (auth) {
    //         return (
    //             <IconButton color="inherit" size="large">
    //                 <Badge
    //                     badgeContent={notifications.length}
    //                     color="warning"
    //                     variant="standard"
    //                     >
    //                     <Notifications color="white" />
    //                 </Badge>
    //             </IconButton>
    //         )
    //     }
    // }

    useEffect(()=>{
        renderMenu();
    },[user]);
    
    let screenWidth = screen.width;

    return (
        <div
            elevation={0}
            style={{ backgroundColor: colors.grey[800], marginTop:"64px" }}
            className="MoblieMenu"
        >
            <div style={{display:'flex', flexDirection:'row'}}>
                {screenWidth <= 900 &&
                    <div className='MobileMenuDisplay'>
                        { auth && 
                            <IconButton sx={{color:"white"}} onClick={()=>setMobileNavOpen(!isMobileNavOpen)} size="large">
                                <MenuIcon />
                            </IconButton>
                        }
                        <div style={{display:'flex', flexDirection:'row', overflowX:'scroll'}}>
                            <ul className="MobileMenuOptions" style={{alignItems:"center"}}>
                                {customListItems.map((item, i) => (
                                    <div style={{backgroundColor:"white", height:'100%', alignContent:'center'}}>
                                        {/* { item.title == "Customers" ?
                                            <StyledBadge badgeContent={message0.length} color="primary" className="BadgeIcon">
                                                <NavItem
                                                href={item.href}
                                                key={item.title}
                                                title={item.title}
                                                icon={item.icon}
                                                />
                                            </StyledBadge>
                                        : */}
                                            <NavItem
                                                href={item.href}
                                                key={item.title}
                                                title={item.title}
                                                icon={item.icon}
                                            />
                                        {/* } */}
                                    </div>
                                ))}
                                {listItems.map((item, i) => (
                                    <div style={{backgroundColor:"white", height:'100%', alignContent:'center'}}>
                                    { listItems[i].title == "Upload Plan" ?
                                        <NavItem
                                            href={item.href}
                                            key={item.title}
                                            title={item.title}
                                            icon={item.icon}
                                            onClick={dispatch({ type: "VALIDATION_RESET" })}
                                        />
                                    :
                                        <NavItem
                                            href={item.href}
                                            key={item.title}
                                            title={item.title}
                                            icon={item.icon}
                                        />
                                    }
                                    </div>
                                ))}
                            </ul>
                            {/* { userInfo?.isAdmin == "True" &&
                                <ul className="MobileMenuOptions">
                                    {superAdmin.map((item) => (
                                            <div> */}
                                            {/* { item.title == "Companies" ?
                                                <StyledBadge badgeContent={pendingCompany.length} color="primary" className="BadgeIcon">
                                                    <NavItem
                                                    href={item.href}
                                                    key={item.title}
                                                    title={item.title}
                                                    icon={item.icon}
                                                    />
                                                </StyledBadge>
                                            : */}
                                                {/* <NavItem
                                                    href={item.href}
                                                    key={item.title}
                                                    title={item.title}
                                                    icon={item.icon}
                                                /> */}
                                            {/* } */}
                                        {/* </div>
                                    ))}
                                </ul>
                            } */}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

// MobileMenu.propTypes = {
//     className: PropTypes.string,
//     onMobileNavOpen: PropTypes.func
// };

export default MobileMenu;