import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import homeBanner from "../../images/Botmark-Banner-Design-With-Logos.jpg";
import DeanPhoto from "../../images/Dean-Photo.jpeg";
import ArnelPhoto from "../../images/Arnel-Photo.jpeg";
import Youtube from 'react-youtube';

function HomeContact(){
    const opts = {
        height: '425px',
        width: '100%',
    }
    return(
        <div>
            <div style={{width:"100%", height:"100vh",backgroundColor:"#212121"}}>
                <div style={{display:"flex", flexDirection:"column", marginLeft: "20px", justifyContent:"end", paddingTop:"20px", marginLeft:"10%"}}>
                    <h1 style={{textAlign:"Left", margin:"0px", color:"white", marginBottom:"20px"}}>Get In Touch</h1>
                    <h3 style={{color:"darkgrey", textAlign:"Left", margin:"0px", marginTop:"5px", color:"white"}}>Office Address</h3>
                    <p style={{margin:"", textAlign:"left", color:"lightgray"}}>
                        7945 168 Ave SE
                        <br/>
                        Mooreton, ND 58061                                
                    </p>
                    <h3 style={{color:"darkgrey", textAlign:"Left", margin:"0px", marginTop:"5px", color:"white", marginTop:"20px"}}>Employee Contact</h3>
                    <p style={{margin:"", textAlign:"left", color:"lightgray"}}>
                    <h4><b>Dean Onchuck</b></h4>
                    <i>Sales</i>
                    <br/>
                    dean@botmark.com
                    <br/>
                    (701) 640-2344
                    <br/>
                    <br/>

                    <h4><b>Arnel Onchuck</b></h4>
                    <i>Operations Manager</i>
                    <br/>
                    layout@layoutspecialties.com
                    <br/>
                    (701) 640-3961                               
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomeContact;