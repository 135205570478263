import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Tabs,
    Tab,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    Wifi,
    WifiOff,
} from 'react-feather';
import CompanyStatusChange from './CompanyStatusChange';
import CompanyLicenseDataGrid from './CompanyLicenseView';
import LicensingCreation from '../licensing/licensing.jsx';
import RulerCreator from '../rulers/RulerCreator';
import CompanyRulerDataGrid from './CompanyRulerView';
import CurrencyFormat from 'react-currency-format';
import CompanyRate from './CompanyRate';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CompanyViewer = () => {
    /// Company data grid for super admins shows all registered companies
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const role = useSelector((state) => state.CompanyReducer.userRole);
    const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const companyData = useSelector((state) => state.CompanyReducer.selectedCompany);
    const [selectedComp, setSelectedComp] = React.useState('00000000-0000-0000-0000-000000000000');
    const [selectedCompany, setSelectedCompany] = React.useState();
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [processStatus, setProcessStatus] = React.useState(companyData?.processExternal);
    const [companyRate, setCompanyRate] = React.useState(companyData?.companyRate);

    console.log("RATE",companyRate)

    console.log("COMPANY", companyData);

    function editStatus(company) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: company.id,
            name: company.name,
            street: company.street,
            city: company.city,
            state: company.state,
            zip: company.zip,
            country: company.country,
            processExternal: company.processExternal,
            email: company.email,
            phoneNumber: company.phoneNumber,
            companyStatus: company.companyStatus,
            companyRate: company.companyRate
        })
        handleOpen1();
    };

    let company = {
        Id: companyData?.id,
        Name: companyData?.name,
        City: companyData?.city,
        Country: companyData?.country,
        State: companyData?.state,
        Street: companyData?.street,
        Zip: companyData?.zip,
        ProcessExternal: processStatus,
        Email: companyData?.email,
        PhoneNumber: companyData?.phoneNumber,
        CompanyStatus: companyData?.companyStatus,
        AccountingEmail: companyData?.accountingEmail,
        CompanyRate: companyData?.companyRate
    };

    /// change company processing status
    function changeProcessExternalStatus(){
        if (companyData?.processExternal === false) {
            // console.log("set true")
            setProcessStatus(true);
            company = {
                Id: companyData?.id,
                Name: companyData?.name,
                City: companyData?.city,
                Country: companyData?.country,
                State: companyData?.state,
                Street: companyData?.street,
                Zip: companyData?.zip,
                ProcessExternal: true,
                Email: companyData?.email,
                PhoneNumber: companyData?.phoneNumber,
                CompanyStatus: companyData?.companyStatus,
                AccountingEmail: companyData?.accountingEmail,
                CompanyRate: companyData?.companyRate
            }
            if ( company?.ProcessExternal === true){
                dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});
            }
        }
        else if (companyData?.processExternal === true) {
            setProcessStatus(false)
            company = {
                Id: companyData?.id,
                Name: companyData?.name,
                City: companyData?.city,
                Country: companyData?.country,
                State: companyData?.state,
                Street: companyData?.street,
                Zip: companyData?.zip,
                ProcessExternal: false,
                Email: companyData?.email,
                PhoneNumber: companyData?.phoneNumber,
                CompanyStatus: companyData?.companyStatus,
                AccountingEmail: companyData?.accountingEmail,
                CompanyRate: companyData?.companyRate
            }
            if ( company?.ProcessExternal === false){
                dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});
            }        
        }
    }

    // function updateCompanyRate () {
    //     console.log(companyRate)
    //     company = {
    //         Id: companyData?.id,
    //         Name: companyData?.name,
    //         City: companyData?.city,
    //         Country: companyData?.country,
    //         State: companyData?.state,
    //         Street: companyData?.street,
    //         Zip: companyData?.zip,
    //         ProcessExternal: companyData?.processExternal,
    //         Email: companyData?.email,
    //         PhoneNumber: companyData?.phoneNumber,
    //         CompanyStatus: companyData?.companyStatus,
    //         AccountingEmail: companyData?.accountingEmail,
    //         CompanyRate: Number(companyRate)
    //     }
    //     dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});        
    // }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'header' },
        { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'header' },
        { field: 'phoneNumber', headerName: 'Number', flex: .75, headerClassName: 'header' },
        { field: 'processExternal', headerName: 'External Processor', flex: .5, headerClassName: 'header',
            renderCell: (cellValues)=>{
                if (cellValues.row.processExternal === true){
                    return(
                        <Chip icon={<Wifi/>} variant="contained" color="primary" label="External"/>
                    )
                } else if(cellValues.row.processExternal === false){
                    return(
                        <Chip icon={<WifiOff/>} variant="contained" color="secondary" label="Internal"/>
                    )
                }
            } 
        },
        { field: 'companyStatus', headerName: 'Company Status', flex: .85, headerClassName: 'header', 
            renderCell: (cellValues) => {
                if (cellValues.row.companyStatus === 0){
                    return(
                        <Button className="statusButton" color="primary" onClick={() => editStatus(cellValues)}>Pending</Button>
                    )
                } else if(cellValues.row.companyStatus === 1){
                    return(
                        <Button className="statusButton" color="success" onClick={() => editStatus(cellValues)}>Approved with Credit</Button>
                    )
                } else if(cellValues.row.companyStatus === 2){
                    return(
                        <Button className="statusButton" color="warning" onClick={() => editStatus(cellValues)}>Approved with out Credit</Button>
                    )
                } else if(cellValues.row.companyStatus === 3){
                    return(
                        <Button className="statusButton" color="error" onClick={() => editStatus(cellValues)}>Denied</Button>
                    )
                }
            }
        },
    ];

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    function fetchCompany(){
        dispatch({ type: 'GET_COMPANY', payload: selectedComp})
        dispatch({ type: 'GET_LICENSES', payload: {companyId: selectedComp, type: '1'} });
        dispatch({ type: 'GET_RULERS_FOR_COMPANY', payload: selectedComp})
    };

    useEffect(() => {
        dispatch({ type: 'GET_ALL_COMPANIES' })
    }, []);

    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function companySelect(event){
        // setSelectedComp(event);
        if (selectedComp != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < allCompanies.length; i++){
                if (selectedComp == allCompanies[i].id){
                    setSelectedCompany(allCompanies[i]);
                    setProcessStatus(allCompanies[i].processExternal)
                }
            }
        } else {
            setSelectedCompany();
            setProcessStatus();
        }
    }

    return (
        <Page title="Companies">
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Company Management
                    </Typography>
                </Box>
                <div style={{justifySelf:'left', display:'flex', flexDirection:'row', width:'50%'}}>
                    <FormControl style={{width:'50%', margin:'2px'}}>
                        <InputLabel id="demo-simple-select-label">Company</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedComp}
                            label="Company"
                            onChange={(event) => setSelectedComp(event.target.value)}
                        >
                            {/* <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem> */}
                            {allCompanies.map((item) =>(
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant='contained' style={{width:"15%", height:'75%', alignSelf:'center', margin:'2px'}} onClick={()=>{fetchCompany()}}>Search</Button>
                </div>
                <Tabs value={value} onChange={handleTabChange} left style={{ borderBottom: '2px solid darkGray', paddingBottom: '10px' }}>
                        <Tab label="Company Info" />
                        <Tab label="Licensing" />
                        <Tab label="Rulers"/>
                        <Tab label="Generate License/ Ruler"/>
                    </Tabs>
                    {/* {errorAlert(file.error)} */}
                <TabPanel value={value} index={0} style={{textAlign:'left'}}>
                    <h2>{companyData?.name}</h2>
                    <div style={{display:'flex'}}>
                        <div style={{width:'50%', margin:5}}>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <Typography><b>Company Reference Id:</b> {companyData?.id}</Typography>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <span><b>Contact Info:</b></span>
                                <Typography>Primary Email: {companyData?.email}</Typography>
                                <Typography>Accounting Email: {companyData?.accountingEmail}</Typography>
                                <Typography>Primary Phone: {companyData?.phoneNumber}</Typography>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <span><b>Address:</b></span>
                                {companyData.name != 'Unassigned' &&
                                    <div>
                                        <Typography>{companyData?.street}</Typography>
                                        <Typography>{companyData?.city}, {companyData?.state}</Typography>
                                        <Typography>{companyData?.country}</Typography>
                                        <Typography>{companyData?.zip}</Typography>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{width:'50%', margin:5}}>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <Typography>
                                    <b>Company Credit Status:</b>
                                    { companyData?.companyStatus === 0 &&
                                            <Button className="statusButton" color="primary" onClick={() => editStatus(companyData)}>Pending</Button>
                                        } 
                                    { companyData?.companyStatus === 1 &&
                                            <Button className="statusButton" color="success" onClick={() => editStatus(companyData)}>Approved with Credit</Button>
                                        }
                                    { companyData?.companyStatus === 2 &&
                                            <Button className="statusButton" color="warning" onClick={() => editStatus(companyData)}>Approved with out Credit</Button>
                                        }
                                    { companyData?.companyStatus === 3 &&
                                            <Button className="statusButton" color="error" onClick={() => editStatus(companyData)}>Denied</Button>
                                        }
                                </Typography>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <CompanyRate/>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <span>If company is capable of processing plans, for self or for customers?</span>
                                <br/>
                                <br/>
                                { companyData?.processExternal === true ?
                                    <Chip icon={<Wifi/>} variant="contained" color="primary" label="For Customers"/>
                                    :
                                    <Chip icon={<WifiOff/>} variant="contained" color="secondary" label="For Self"/>
                                }
                                <div>
                                    <p>To make this company visible to prospective customers, make sure the box below is checked.</p>
                                    <Form>
                                        <Label check>
                                            <Input type="switch" role="switch" checked={companyData?.processExternal} value={companyData?.processExternal} onChange={() => changeProcessExternalStatus()}/>
                                            Processing External Plans
                                        </Label>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <CompanyStatusChange 
                            item={rowItem} 
                            style={style}
                            handleClose1={handleClose1}
                        />
                    </Modal> 
                    {/* <DataGrid
                        components={{
                            Toolbar: CustomToolBar
                        }}
                        columns={columns}
                        rows={allCompanies}
                        sx={{ height: '70vh' }}
                    />
                    */}
                </TabPanel>
                <TabPanel value={value} index={1} style={{}}>
                    <CompanyLicenseDataGrid/>
                </TabPanel>
                <TabPanel value={value} index={2} style={{}}>
                    <CompanyRulerDataGrid/>
                </TabPanel>
                <TabPanel value={value} index={3} style={{}}>
                    <div style={{flexDirection:"row", display:'flex'}}>
                        <div style={{width:"50%", border:'solid 2px darkgray', margin:2, borderRadius:5, padding:5}}>
                            <LicensingCreation selectedCompProp={selectedComp} />
                        </div>
                        <div style={{width:"50%", border:'solid 2px darkgray', margin:2, borderRadius:5, padding:5}}>
                            <RulerCreator selectedCompProp={selectedComp} />
                        </div>
                    </div>
                </TabPanel>
            </Box>
        </Page>
    )
};

export default CompanyViewer;