import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Drawer,
  Hidden,
  List,
  Avatar,
  Divider,
  Typography,
  Badge,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import {
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Home as HomeIcon,
  DollarSign as DollarIcon,
  Info as InfoIcon,
  User as UserIcon,
  Users as UsersIcon,
  LogOut as LogoutIcon,
  Upload as UploadIcon,
  Folder as FolderIcon,
  Film as FilmIcon,
  Search as SearchIcon,
  MessageCircle as MessageIcon,
  Inbox as InboxIcon,
  Clipboard as ClipboardIcon,
  Mail as MailIcon,
  FileText as FileTextIcon,
  FilePlus as FilePlusIcon,
  Layers as LayersIcon,
  FileMinus as FileMinusIcon,
  Printer as PrinterIcon,
  BookOpen as BookOpenIcon,
  Book as BookIcon,
  List as ListIcon,
  Paperclip as PaperclipIcon,
  Thermometer as ThermometerIcon
} from 'react-feather';
import NavItem from './NavItem';
import { useSelector, useDispatch } from 'react-redux';
import SplitToken from '../../mixins/SplitToken';
import "../Layout.css";
import { styled } from '@mui/material/styles';
import getToken from '../../mixins/GetToken';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 21,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}));

const uploadPlan = {
    href: '/upload',
    icon: UploadIcon,
    title: 'Upload Plan'
};

const plans = {
    href: '/',
    icon: FileTextIcon,
    title: 'Plans'
};

const account = {
    href: '/account',
    icon: InfoIcon,
    title: 'Account'
};

const contact = {
    href: '/contact',
    icon: MessageIcon,
    title: 'Contact'
};

const logout = {
    href: '/logout',
    icon: LogoutIcon,
    title: 'Log Out'
};

const login = {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
};

const register = {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
};

const tutorial = {
    href: '/instructions',
    icon: FilmIcon,
    title: 'Tutorial'
};

const findProcessor = {
    href: '/findProcessor',
    icon: SearchIcon,
    title: 'Find Processor'
}

const planInbox = {
    href: '/planInbox',
    icon: LayersIcon,
    title: 'Plan Inbox'
};

const downloaderPlanInbox = {
    href: '/',
    icon: LayersIcon,
    title: 'Plan Inbox'
};

const receivedEstimates = {
    href: '/InvoicesToMe',
    icon: BookOpenIcon,
    title: 'Received Invoices'
};

const sentEstimates = {
    href: '/InvoicesFromMe',
    icon: BookIcon,
    title: 'Sent Invoices'
};

const customerManagement = {
    href: '/customers',
    icon: InboxIcon,
    title: 'Customer Management'
};

const processorContacts = {
    href: '/Contacts',
    icon: UsersIcon,
    title: 'Processor Contacts'
};

///standard menu render options
const items = [ tutorial ];

///menu render options for processor Admins
const admin = [ planInbox, uploadPlan, plans, receivedEstimates, findProcessor ];

///menu render options for external processor Admins
const externalAdmin = [ planInbox, uploadPlan, plans, sentEstimates, 
    receivedEstimates, customerManagement, processorContacts, findProcessor ];

/// menu options for downloader Admins
const downloaderAdmin = [ downloaderPlanInbox, receivedEstimates, processorContacts, findProcessor ];

/// menu options for downloader Admins
const downloaderOnly = [ downloaderPlanInbox ];

/// menu options for downloader Admins
const allBilling = [ sentEstimates, receivedEstimates ];

/// menu options for downloader Admins
const recievedBilling = [ receivedEstimates ];

/// menu options for a planner user
const planner = [ plans, uploadPlan ];

/// menu options for a planner user
const plannerDownload = [ plans ];

/// login/out menu options
const loginItems = [ login, register ];

///menu options for Super Admins (develpers/BotwebOwner)
const superAdmin = [
    {
        href: '/companiesViewer',
        icon: ListIcon,
        title: 'Companies'
    },
    {
        href: '/BotmarkInvoiceTable',
        icon: BookIcon,
        title: 'Botmark Invoices'
    },
    {
        href: '/RulerCreator',
        icon: ThermometerIcon,
        title: 'Rulers'
    },
    {
        href: '/licensing',
        icon: ClipboardIcon,
        title: 'Licensing'
    },
    {
        href: '/PDFupload',
        icon: PaperclipIcon,
        title: 'PDF/Tutorial Upload'
    },
];

const roleOptionMap = new Map();
roleOptionMap.set("AD", [...admin]);
roleOptionMap.set("EXAD", [...externalAdmin]);
roleOptionMap.set("DAD", [...downloaderAdmin]);
roleOptionMap.set("BI", [...allBilling]);
roleOptionMap.set("PL", [...planner]);
roleOptionMap.set("RBI", [...recievedBilling]);
roleOptionMap.set("DO", [...downloaderOnly]);
roleOptionMap.set("PD", [...plannerDownload]);

const MyDrawer = styled(Drawer)({
    // width: 256,
    // top: 64, 
    // marginTop: '64px',
    },
    // ` & > div {
    // width: 256px;
    // border: none;
    // margin-top: 64px;
    // height: 94%
    // }`
);

const NavBar = ({ onMobileClose, openMobile, isMobileNavOpen, setMobileNavOpen }) => {
    const dispatch = useDispatch();
    const allMessages = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    const message0 = [];
    allMessages?.forEach(messagePusher);
    function messagePusher(allMessages) {
        if (allMessages.requestStatus === 0){
        message0.push({
            id: allMessages.id,
            companyId: allMessages.companyId,
            created: allMessages.created,
            deleted: allMessages.deleted,
            message: allMessages.message,
            requestStatus: allMessages.requestStatus,
            userId: allMessages.userId,
            userEmail: allMessages.userEmail,
            customerName: allMessages.customerName,
            userCompany: allMessages.companyContact,
            companyStatus: allMessages.companyStatus,
            userCompanyId: allMessages.userCompanyId,
    })}};

    const user = useSelector((state) => state.UserReducer.isAuthenticated);
    const processorVersion = useSelector((state) => state.FileReducer.processorVersion.data);
    const currentCompany = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const allCompany = useSelector((state) => state.CompanyReducer.allCompanies);
    const listItems = [...items];
    const token = getToken();
    const userInfo = SplitToken();
    const location = useLocation();
    const [customListItems, setCustomListItems] = React.useState([]);

    const startDateInitialState = dayjs().subtract(1, "years").startOf('day').format();
    const endDateInitialState = dayjs().endOf('day').format();
    
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);

    function setSelectedCustomer(event){
        dispatch({ type: 'CUSTOMER_SELECTION', payload: event })
        dispatch({ type: 'GET_COMPANY_FILES', payload: {companyId: currentCompany?.id, page: 0, pageSize: 25, customerId: event }})
        dispatch({ type: 'GET_COMPANY_INVOICES', payload: {companyId: currentCompany?.id, page: 0, pageSize: 25, customerId: event, startDate: startDateInitialState, endDate: endDateInitialState }})
    };

    const pendingCompany = [];
    allCompany?.forEach(companyPusher);
    function companyPusher(allCompany) {
        if (allCompany.companyStatus === 0){
            pendingCompany.push({
                companyStatus: allCompany.companyStatus,
            })
        }
    };

    function renderMenu() {
        const isCompanyApproved = !!userCompany[0] 
        && (userCompany[0].companyStatus == 1 || userCompany[0].companyStatus == 2)

        if (!isCompanyApproved) {
            //TODO set the options accordingly
            return;
        }

        const role = user.theUserRoles[0];
        let menuOptions;
        const isExternalProcessor = !!userCompany[0].processExternal;

        if (role === "AD" && isExternalProcessor) {
            menuOptions = roleOptionMap.get("EXAD");
        }
        else {
            menuOptions = roleOptionMap.get(role) ?? [];
        }        

        setCustomListItems(menuOptions);
        // if (openMobile && onMobileClose) {
        //     onMobileClose();
        // };
        // // setCustomListItems(menuOptions);
        // if (openMobile && onMobileClose) {
        //     onMobileClose();
        // };
    };

    useEffect(()=>{
        dispatch({type: "FETCH_PROCESSOR_VERSION"});
        renderMenu();
        dispatch({type: "GET_ALL_COMPANIES"});
    },[user , userCompany],[processorVersion]);

    useEffect(()=>{

    },[currentCompany]);

    let screenWidth = screen.width; 

    const content = (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ border: '1px solid #e0e0e0' }}
            >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    p: 1
                }}
            >
                <Avatar
                    component={RouterLink}
                    to="/account"
                    sx={{
                        cursor: 'pointer',
                        width: 50,
                        height: 50,
                        m: 1,
                    }}
                    className='AvatarIcon'
                />
                <div>
                <Typography variant="h6" style={{marginLeft: 5}}>
                    Welcome:
                </Typography>
                <Typography variant='subtitle1'>
                    {user.userName}
                </Typography>
                </div>
            </Box>
            <div style={{marginLeft:10}}>
                <span>v. {processorVersion}</span>
            </div>
            <div>
                {
                 (user.theUserRoles[0] === "AD" && currentCompany?.processExternal == true) ||
                 (user.theUserRoles[0] === "PL" && currentCompany?.processExternal == true) || 
                 (user.theUserRoles[0] === "BI" && currentCompany?.processExternal == true) ?
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customerSelect}
                            label="Customer"
                            onChange={(event) => setSelectedCustomer(event.target.value)}
                        >
                            <MenuItem value={'00000000-0000-0000-0000-000000000000'}>All</MenuItem>
                            {allMessages.map((item) =>(
                                <MenuItem value={item.userCompanyId}>
                                        {item.requestStatus == 0 &&
                                            <span style={{color:'purple'}}>{item.companyContact} : {item.customerName}</span>
                                        }
                                        {item.requestStatus == 1 &&
                                            <span style={{color:'green'}}>{item.companyContact} : {item.customerName}</span>
                                        }
                                        {item.requestStatus == 2 &&
                                            <span style={{color:'darkOrange'}}>{item.companyContact} : {item.customerName}</span>
                                        }
                                        {item.requestStatus == 3 &&
                                            <span style={{color:'red'}}>{item.companyContact} : {item.customerName}</span>
                                        }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    :
                    <></>
                }
                {/* {user.theUserRoles[0] === "PL" &&
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customerSelect}
                            label="Customer"
                            onChange={(event) => setSelectedCustomer(event.target.value)}
                        >
                            <MenuItem value={'00000000-0000-0000-0000-000000000000'}>All</MenuItem>
                            {allMessages.map((item) =>(
                                <div>
                                    {item.requestStatus == 0 &&
                                        <MenuItem style={{color:'purple'}} value={item.userCompanyId}>{item.companyContact} : {item.customerName}</MenuItem>
                                    }
                                    {item.requestStatus == 1 &&
                                        <MenuItem style={{color:'green'}} value={item.userCompanyId}>{item.companyContact} : {item.customerName}</MenuItem>
                                    }
                                    {item.requestStatus == 2 &&
                                        <MenuItem style={{color:'darkOrange'}} value={item.userCompanyId}>{item.companyContact} : {item.customerName}</MenuItem>
                                    }
                                    {item.requestStatus == 3 &&
                                        <MenuItem style={{color:'red'}} value={item.userCompanyId}>{item.companyContact} : {item.customerName}</MenuItem>
                                    }
                                </div>
                            ))}
                        </Select>
                    </FormControl>
                } */}
            </div>
            <Divider/>
            <Box p={1}>
                <List className="BadgeIcon">
                {customListItems.map((item, i) => (
                    <div>
                        { item.title == "Customers" ?
                            <StyledBadge badgeContent={message0.length} color="primary" className="BadgeIcon">
                                <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                />
                            </StyledBadge>
                        :
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                            />
                        }
                    </div>
                ))}
                </List>
            <Divider/>
                <List>
                    {listItems.map((item, i) => (
                        <div>
                        { listItems[i].title == "Upload Plan" ?
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                onClick={dispatch({ type: "VALIDATION_RESET" })}
                            />
                        :
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                            />
                        }
                        </div>
                    ))}
                </List>
            <Divider/>
            { userInfo?.isAdmin == "True" &&
                <List>
                    {superAdmin.map((item) => (
                               <div>
                               { item.title == "Companies" ?
                                   <StyledBadge badgeContent={pendingCompany.length} color="primary" className="BadgeIcon">
                                       <NavItem
                                       href={item.href}
                                       key={item.title}
                                       title={item.title}
                                       icon={item.icon}
                                       />
                                   </StyledBadge>
                               :
                                   <NavItem
                                       href={item.href}
                                       key={item.title}
                                       title={item.title}
                                       icon={item.icon}
                                   />
                               }
                           </div>
                    ))}
                </List>
            }
            </Box>
            <Divider/>
            <Box flexGrow={1} />
        </Box>
    );

    const loginContent = (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ border: '1px solid #e0e0e0' }}
        >
            <Box p={2}>
                <List>
                    {loginItems.map((item) => (
                    <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                    />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    )

    return <>
        {/* <Hidden lgUp>
            <Drawer
                anchor="left"
                sx={{ width: 256}}
                onClose={onMobileClose}
                open={isMobileNavOpen}
                variant="temporary"
                classes={{
                    paper: {
                        width: '256px',
                    }
                }}
            >
                {user ? content : loginContent}
            </Drawer>
        </Hidden> */}
        {/* <Hidden lgDown> */}
        {screenWidth <= 900 ?
            <div
                // anchor="left"
                // sx={{ border: 'none'}}
                open={isMobileNavOpen}
                // variant="persistent"
                className="sideMenu"
                hidden={isMobileNavOpen}
            >
                {user ? content : loginContent}
            </div>
            :
            <div
                // anchor="left"
                // sx={{ border: 'none'}}
                open={isMobileNavOpen}
                // variant="persistent"
                className="sideMenu"
            >
                {user ? content : loginContent}
            </div>
        }
        {/* </Hidden> */}
    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default NavBar;