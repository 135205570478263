import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Tabs,
    Tab,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';

function CompanyRate(){
    const companyData = useSelector((state) => state.CompanyReducer.selectedCompany);
    const [rate, setRate] = React.useState(companyData?.companyRate);
    const dispatch = useDispatch();

    let company = {
        Id: companyData?.id,
        Name: companyData?.name,
        City: companyData?.city,
        Country: companyData?.country,
        State: companyData?.state,
        Street: companyData?.street,
        Zip: companyData?.zip,
        ProcessExternal: companyData?.processExternal,
        Email: companyData?.email,
        PhoneNumber: companyData?.phoneNumber,
        CompanyStatus: companyData?.companyStatus,
        AccountingEmail: companyData?.accountingEmail,
        CompanyRate: rate
    };

    function onSubmit(){
        dispatch({ type: "UPDATE_COMPANY_OBJECT", payload: company});
        // console.log(company)
    };

    return(
        <Form>
            <div style={{display:'flex', marginTop:'10px'}}>
                <Label style={{margin:'auto', marginRight:'10px', width:'20%'}}><b>Company Rate:</b></Label>
                <TextField
                    fullWidth
                    onChange={(event)=>setRate(event.target.value)}
                    value={rate}
                />
            </div>
            <Button variant="contained"
                fullWidth sx={{ marginTop: '5px', height: '3.4375em', padding: '16.5px 14px', width:'82%', marginLeft:'18%' }}
                color="primary"
                onClick={()=>onSubmit()}
                >
                Update Company Info
            </Button>
        </Form>
    )
}

export default CompanyRate;