import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import homeBanner from "../../images/Botmark-Banner-Design-With-Logos.jpg";
import DeanPhoto from "../../images/Dean-Photo.jpeg";
import ArnelPhoto from "../../images/Arnel-Photo.jpeg";
import Youtube from 'react-youtube';

function HomePage(){
    const opts = {
        height: '425px',
        width: '100%',
    }
    return(
        <div>
            <img style={{width:'100%', boxShadow:" 1px 4px 8px rgba(0, 0, 0, 0.2)"}} src={homeBanner}/>
        </div>
    )
}

export default HomePage;