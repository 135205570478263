import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { 
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import rootSaga from './redux/sagas';
import rootReducer from './redux/reducers';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; dayjs.extend(utc);
import timezone from 'dayjs/plugin/timezone'; dayjs.extend(timezone);

const sagaMiddleware = createSagaMiddleware();

const middlewareList = process.env.NODE_ENV === 'development' ? [sagaMiddleware, logger] : [sagaMiddleware];

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(...middlewareList)
    )
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
