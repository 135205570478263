import React, { useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Input,
    TextField
} from '@mui/material';
import { Folder } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "./PDFUpload.css";

function PDFUploadPage(){
    ///// Currently vids either require a youtube Id or a embed version of a youtube link to function /////
    ///// PDF Upload form select relevant pdf type to display it in the correct location on the website /////
    const navigate = useNavigate();
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [file, setFile] = React.useState([]);
    const [review, setReview] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    const loading = useSelector((state) => state.FileReducer.isLoading);
    const validation = useSelector((state) => state.FileReducer.file);
    const dispatch = useDispatch();
    const [agType, setAgType] = React.useState('');
    const [vidUrl, setVidUrl] = React.useState();
    const [vidType, setVidType] = React.useState('');
    const handleChange = (event) => {
        setAgType(Number(event.target.value));
    };
    const handleChangeVideoType = (event) => {
        setVidType(Number(event.target.value));
    };

    React.useEffect(() => {
        if (validation.file.length > 0) {
            setReview(true)
        }
        setFile(acceptedFiles)
    },[acceptedFiles],[validation.file]);

    const files = acceptedFiles.map(file => (
        <Tooltip key={file.path} title="Click to select different file">
            <ListItem sx={{ border: '1px solid black' }}>
                    <ListItemIcon>
                        <Folder/>
                    </ListItemIcon>
                    <ListItemText>
                        {file.path} - {file.size} bytes
                    </ListItemText>
            </ListItem>
        </Tooltip>
    ));

    function uploadVideoUrl(){
        dispatch({ type: 'UPLOAD_VIDEO_URL', payload: {vidUrl: vidUrl, companyId: company.id, vidType: vidType}});
        setVidType('');
        setVidUrl('');
    };

    console.log("Vid type: ", vidType);
    console.log("Pdf type: ", agType)

    const handleUpload = () => {
        if ( file.length < 1 ) return Toast.fire({ title: 'File required', icon: 'error' });
        else if (!file[0].name.includes('.pdf')) return Toast.fire({ title: '.PDF FILES ONLY', icon: 'error' });
        else if (agType == null || agType == undefined) return Toast.fire({ title: 'Please Enter a Categorical Type for this Document.', icon: 'error' });
        else dispatch({ type: 'UPLOAD_PDF_FILE', payload: {file: file[0], name: file[0].name, companyId: company.id, PDFType: agType}, setFile, setAgType, navigate })
    };

    return (
        <Box sx={{margin: "5%"}}>
            <Box style={{border:'solid black 2px'}}>
                <h2 style={{margin:'5px'}}>PDF Uploads</h2>
                <p>Select Type of Document to assign it to a location on the site.</p>
                <Typography>License Agreement PDF Upload</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Box 
                        sx={{ 
                            width: '80%',
                            height: '70%',
                            display: 'flex',
                            justifyContent: 'center', 
                            alignItems: 'center',
                            borderWidth: '2px',
                            borderRadius: '2px',
                            borderStyle: 'dashed',
                            padding: '20px',
                            transition: 'border .24s ease-in-out'
                        }}
                        {...getRootProps({className: 'dropzone'})}
                    >
                        <input disabled={company === undefined} {...getInputProps()}/>
                        { acceptedFiles.length > 0 ? <List>{files}</List> : <p>Drag 'n' drop .PDF file here, or click to select a .PDF file</p> }
                    </Box>
                </Box>
                <Box sx={{width:"100%", height:"100%", marginTop: '5%'}}>
                    <Typography sx={{}}>Set PDF Type</Typography>
                    <Box component="form" className="pdf" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 2, minWidth: 120 }}>
                            {/* <InputLabel htmlFor="demo-dialog-native">PDF Type</InputLabel> */}
                            <Select
                                native
                                onChange={handleChange}
                                value={agType}
                                // input={<OutlinedInput label="Status" id="demo-dialog-native" />}
                            >
                                <option value={0}>Select type</option>
                                <option value={1}>Upload Agreement PDF</option>
                                <option value={2}>Download Agreement PDF</option>
                            </Select>
                        </FormControl>
                    </Box>
                    <Button sx={{ width: '50%', margin: "10px" }} 
                        variant="contained" 
                        onClick={handleUpload}
                        disabled={agType == 0}
                    >
                        Upload .PDF
                    </Button>
                </Box>
            </Box>
            <Box style={{marginTop:'40px', border:'solid black 2px'}}>
                <Typography style={{margin:'5px'}}>YouTube Video</Typography>
                <Typography style={{margin:'5px'}}>Please Upload YouTube Video ID. If you copy the url it is the end portion of it after the '='.</Typography>
                <Typography style={{margin:'5px'}}>Example: https://www.youtube.com/watch?v=fey1K2tLwcY ===== fey1K2tLwcY</Typography>
                <FormControl style={{width:'50%'}}>
                    <TextField onChange={(event) => setVidUrl(event.target.value)} value={vidUrl}></TextField>
                    <br/>
                    <Select
                        native
                        onChange={handleChangeVideoType}
                        value={vidType}
                    >
                        <option value={0}>Select type</option>
                        <option value={1}>Admin Video</option>
                        <option value={2}>Planner Video</option>
                        <option value={3}>Downloader Video</option>
                        <option value={4}>Downloader Admin Video</option>
                        <option value={5}>All Billing Video</option>
                        <option value={6}>Recieved Billing Video</option>
                    </Select>
                </FormControl>
                <br/>
                <Button 
                    sx={{ width: '50%', margin: "10px" }}
                    variant="contained" 
                    onClick={uploadVideoUrl}
                    disabled={vidType == 0}
                >
                    Save Video URL
                </Button>
            </Box>
        </Box>
    )
};

export default PDFUploadPage;
