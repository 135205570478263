import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
} from "@mui/material";
import CustomerDataGrid from './CustomerDataGrid';

const CustomersPage = () => {
    /// container page for Customer View. Displays all customers that have reached out to a processor
    const currentCompany = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const allMessages = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    const dispatch = useDispatch();

    // const message0 = [];

    // allMessages?.forEach(messagePusher);
    // function messagePusher(allMessages) {
    //     message0.push({
    //         id: allMessages.id,
    //         companyId: allMessages.companyId,
    //         created: allMessages.created,
    //         deleted: allMessages.deleted,
    //         message: allMessages.message,
    //         requestStatus: allMessages.requestStatus,
    //         userId: allMessages.userId,
    //         userEmail: allMessages.userEmail,
    //         name: `${allMessages.userFirstName} ` + `${allMessages.userLastName}`,
    //         reply: allMessages.reply,
    //         userCompany: allMessages.companyContact,

    //     })
    // };

    useEffect(() => {
        dispatch({ type: 'FETCH_MESSAGES', payload: currentCompany.id })
    }, [])

    return(
        <Box style={{paddingTop:"30px", paddingRight:'30px'}}>            
            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography textAlign={"center"} color={"blue"} variant='h4'>
                    Customers
                </Typography>
            </Box>                    
            <Box width='100%' textAlign="center">
                <Typography variant="h5" >
                </Typography>
            </Box>
            <CustomerDataGrid messages={allMessages}/>
        </Box>
    )
}

export default CustomersPage;