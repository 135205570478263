import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

function LoginPage(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.UserReducer.isLoading);
    return(
        <div>
             <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress size={100} color="warning" />
                    </Backdrop>
                    <Container maxWidth="sm" style={{border:'solid black 2px', borderRadius:'10px', boxShadow: '5px 5px 5px 3px rgba(168,168,168,0.45)', margin:"auto", marginTop:"10%"}}>
                        <Formik
                            initialValues={{
                                userName: "",
                                password: "",
                            }}
                            validationSchema={Yup.object().shape({
                                userName: Yup.string().max(255).required("Username is required"),
                                password: Yup.string().max(255).required("Password is required"),
                            })}
                            onSubmit={(values) => {
                                dispatch({
                                    type: "USER_LOGIN",
                                    payload: { UserName: values.userName, Password: values.password },
                                });
                                navigate("/", { replace: true });
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Sign In
                                    </Typography>
                                </Box>
                                <Box mt={3} mb={1}></Box>
                                <TextField
                                    error={Boolean(touched.userName && errors.userName)}
                                    fullWidth
                                    helperText={touched.userName && errors.userName}
                                    label="Username"
                                    margin="normal"
                                    name="userName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.userName}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign in now
                                    </Button>
                                </Box>
                                <Typography color="textSecondary" variant="body1">
                                    Don&apos;t have an account?{" "}
                                    <Link component={RouterLink} to="/register" variant="h6">
                                        Sign up
                                    </Link>
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    <Link component={RouterLink} to="/requestpassword" variant="h6">
                                        Forgot Login
                                    </Link>
                                </Typography>
                            </form>
                            )}
                        </Formik>
                    </Container>
        </div>
    )
}

export default LoginPage;