import React from "react";
import { useRef } from "react";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    Box,
    Button,
    Tabs,
    Tab,
    CircularProgress
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import './PaymentSummary.css';
import PointListTable from "./PointListTable";
import { usePDF } from 'react-to-pdf';
import EstimateForm from "./EstimateForm";

const PaymentSummary = ({handleCloseEstimate, estimateObj}) => {
    
    /// This page is to create an Invoice, is somewhat complex handle with care /// 
    const file = useSelector((state) => state.FileReducer.file.info);
    const fileReport = useSelector((state) => state.FileReducer.file);
    const CompanyPrices = useSelector((state) => state.CompanyReducer.CompanyPrices);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const [selectedCustomer, setSelectedCustomer] = React.useState();
    const [invoiceOptions, setInvoiceOptions] = React.useState([]);
    const [invoiceTaxOptions, setInvoiceTaxOptions] = React.useState([]);
    const [invoiceTaxTotals, setInvoiceTaxTotals] = React.useState([]);
    const [totalInvoiceSum, setTotalInvoiceSum] = React.useState(0);
    const [invoiceAfterTaxSum, setInvoiceAfterTaxSum] = React.useState(0);
    const [pointTypeTotal, setPointTypeTotal] = React.useState(0);
    const [results, setResults] = React.useState(fileReport.file);
    const [summary, setSummary] = React.useState(fileReport.summary);
    const [currentStatus, setCurrentStatus] = React.useState(estimateObj.isInvoiceAccepted);
    const [terms, setTerms] = React.useState('');
    const [invoiceNum, setInvoiceNum] = React.useState(0);
    const [value, setValue] = React.useState(0);

    const dispatch = useDispatch();

    let totalInvoice = 0;
    let taxTotal = 0;
    let companyId = userCompany[0].id;

    /* console.log("ESTIMATEOBJ", estimateObj); */
    console.log("CUSTOMER", selectedCustomer)
    
    /// This sets the dropdown to select a customer for a plan in invoicing ///
    const allCustomers = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    
    const customers = [];
    allCustomers?.forEach(customerPusher);

    function customerPusher(allCustomers) {
        if (allCustomers.requestStatus === 1 || allCustomers.requestStatus === 2) {
        customers.push({
            id: allCustomers.id,
            companyId: allCustomers.companyId,
            created: allCustomers.created,
            deleted: allCustomers.deleted,
            message: allCustomers.message,
            requestStatus: allCustomers.requestStatus,
            userId: allCustomers.userId,
            userEmail: allCustomers.userEmail,
            customerName: allCustomers.customerName,
            userCompany: allCustomers.companyContact,
            companyStatus: allCustomers.companyStatus,
            userCompanyId: allCustomers.userCompanyId
        })}
    };

    /// For File name selection in invoice ///
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    };

    /// To Remove a row from the invoice ///
    function recalculateTotals() {
        taxTotal = 0;
        totalInvoice = 0;
        invoiceOptions.map((option) => totalInvoice += option.rate * option.quantity);
        invoiceTaxOptions?.map(x => taxTotal += x.rate * totalInvoice);

        setTotalInvoiceSum(totalInvoice)
        setInvoiceAfterTaxSum((taxTotal + totalInvoice).toFixed(2));
    };

    function initializeInvoice() {
        
        let data;

        if (estimateObj.invoiceJobId != null) {
            data = JSON.parse(estimateObj?.invoiceValues);
        } else {

            data = JSON.parse(estimateObj?.botmarkInvoiceValues);
        }

        setSelectedCustomer(data?.customerInfo);
        setInvoiceOptions(data?.items);
        setInvoiceTaxOptions(data?.taxes);
        setTerms(data?.terms);
        setInvoiceNum(data.estimateNumber);
        console.log("DATA",data)

        invoiceTaxOptions.map((taxOption) => taxTotal += taxOption.total);

        pointCounter();

        if (estimateObj.customerId != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < customers.length; i++){
                if (estimateObj.customerId == customers[i].userId){
                    setSelectedCustomer(customers[i]);
                }
            }
        } else {
            setSelectedCustomer();
        }
    };
    
    function clearNclose() {
        handleCloseEstimate();
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    let pointTotal = 0;
    let pointList = JSON.parse(estimateObj.pointList);

    function pointCounter() {

        for (let i = 0; i < pointList?.length; i++) {
            if (pointList[i].TypeEnum == 1 || pointList[i].TypeEnum == 2 || pointList[i].TypeEnum == 3 ) {
                pointTotal += pointList[i].Quantity;
            }
        }
        setPointTypeTotal(pointTotal);
    }
    
    React.useEffect(() => {
        dispatch({ type: 'GET_PRICES', payload: companyId });
        dispatch({ type: 'FIND_BOTMARK_PRICES' });
        initializeInvoice();
    },[]);
    
    React.useEffect(() => {
        recalculateTotals();
    },[invoiceOptions, invoiceTaxOptions]);

    // const options = {
        // method: 'open'
        // method: 'save'
        // method: 'build'
    // }
    // const targetRef = useRef();

    const { toPDF, targetRef } = usePDF({ filename: `${estimateObj?.companyContact?.replace(' ', '_')}-${estimateObj?.name?.split('.')[0]}.pdf`, method:'build' });

    return (
        <Container style={{ backgroundColor: 'lightgray', maxWidth: '100vw', position: 'fixed', height:'100%' }}>
            <Container className='estimatePage' maxWidth="sm" style={{ padding: 20, width: '80vw', display: 'flex', flexDirection: 'column', overflowY: 'scroll', height:'inherit' }}>
            <Box sx={{ width: '95%' }}>
                <Button className="closeButton" onClick={()=> clearNclose()} style={{ fontSize: 24, fontWeight: 600, margin: 0, padding: 0}} >X</Button>
                <Tabs value={value} onChange={handleTabChange} centered style={{borderBottom:'2px solid black', paddingBottom:'10px'}}>
                    <Tab label="Estimate Review"/>
                    <Tab label="Validation & Summary"/>
                    {/* <Tab label="Summary Sequence"/> */}
                </Tabs>
                    {/* {errorAlert(file.error)} */}
                <TabPanel value={value} index={0} style={{}}>

                <Container ref={targetRef}>
                    <Typography variant="h4" align="center">
                        Estimate Builder
                    </Typography>
                    <Typography align="center"><p>Fill out information here before presenting the estimate to a customer.</p></Typography>
                    <p style={{ display: "flex", justifyContent: 'right', width: "100%" }}>
                        <span style={{ marginRight: 50 }}>Date: {moment().format("MM/DD/YYYY")}</span>
                        <span># {invoiceNum}</span>
                    </p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, margin: 2 }}>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                    <h4 style={{ display: "flex", justifyContent: 'left' }}>From Processor:</h4>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userCompany[0]?.name}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userCompany[0]?.street}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userCompany[0]?.city}, {userCompany[0]?.state} {userCompany[0]?.zip}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userCompany[0]?.email}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userCompany[0]?.phoneNumber}</span></span>
                                </div>
                            </div>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                    <h4 style={{ display: "flex", justifyContent: 'left' }}>To Customer:</h4>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {selectedCustomer?.userCompany}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {selectedCustomer?.customerName}</span></span>
                                    <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Email: {selectedCustomer?.userEmail}</span></span>
                                    {/* <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Customer Credit Status</span></span>
                                    {selectedCustomer?.requestStatus === 0 &&
                                        <span style={{color:'purple', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                    }
                                    {selectedCustomer?.requestStatus === 1 &&
                                        <span style={{color:'green', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                    }
                                    {selectedCustomer?.requestStatus === 2 &&
                                        <span style={{color:'darkOrange', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                    }
                                    {selectedCustomer?.requestStatus === 3 &&
                                        <span style={{color:'red', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                    } */}
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, margin: 2 }}>
                        </div>
                        <PointListTable pointList={pointList} name={estimateObj.name} id={estimateObj.id} pointTypeTotal={pointTypeTotal}/>
                    </div>
                    <br />
                    <Typography variant="body1">
                        <b>Estimate</b>
                    </Typography>
                    <br />
            <br/>
            <EstimateForm 
                estimateObj={estimateObj}
                handleCloseEstimate={handleCloseEstimate}
            />
            <br/>
            <br/>
            <Typography variant="body1">
                Job Name: {file.name}<br/> {/**sometimes paid files will be replaced by original files https://app.clickup.com/t/2q4vvg8 */}
                Purchase includes: {getLastItem(file.paidFileUrl)}, {getLastItem(file.paidTxtUrl)} <br/>
            </Typography>
            <br/>
        </Container>
        </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box m={2} sx={{  display: 'flex', flexDirection:'row', justifyContent: 'center',height: '100%', minHeight: '300px'}}>
                        <div style={{flex:2, margin: 20}}>
                            <h4 style={{textAlign:'left'}}>Validation:</h4>
                            <pre style={{textAlign:'left'}}>
                                {results}
                            </pre>
                        </div>
                        <div style={{flex:1, margin:20}}>
                            <h4 style={{textAlign:'left'}}>Summary:</h4>
                                <pre style={{textAlign:'left'}}>
                                    {summary}
                                </pre>
                        </div>
                    </Box> 
                </TabPanel>
            </Box>
        </Container>
        </Container>
    )
};

export default PaymentSummary;