import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
} from "@mui/material";

function CompanyStatusChange({item, style, handleClose1 }){
    /// allow for super admin to change business status
    const dispatch = useDispatch();
    console.log("ITEM", item);
    const [status, setStatus] = React.useState(item?.companyStatus);
    const handleChange = (event) => {
        setStatus(Number(event.target.value));
        console.log("Status#",status);
    };

    let one = 1;

    function updateCompanyStatus(item){
        var companyToSend = {
            Id: item.id,
            Name: item.name,
            Street: item.street,
            CompanyStatus: status,
            Email: item.email,
            PhoneNumber: item.phoneNumber,
            City: item.city,
            State: item.state,
            Zip: item.zip,
            Country: item.country,
            ProcessExternal: item.processExternal,
            companyRate: item.companyRate
        }
        console.log("COMPANY_STATUStoSEND", companyToSend);
        dispatch({ type: 'UPDATE_COMPANY_OBJECT', payload: companyToSend, one });
        handleClose1();
    };

    return(
        <Box sx={style}>
            <Typography>Set Status</Typography>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 2, minWidth: 120 }}>
                    <InputLabel htmlFor="demo-dialog-native">Status</InputLabel>
                    <Select
                        native
                        value={status}
                        onChange={handleChange}
                    >
                        <option aria-label="None" value="" />
                        <option value={0}>Pending</option>
                        <option value={1}>Approved w/ Credit</option>
                        <option value={2}>Approved w/o Credit</option>
                        <option value={3}>Denied</option>
                    </Select>
                    <Button onClick={()=>updateCompanyStatus(item)}>Update Status</Button>
                </FormControl>
            </Box>
        </Box>
    )
};

export default CompanyStatusChange;